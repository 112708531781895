import "es6-promise/auto";
import images from './images';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import Swiper from 'swiper';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

// App main
const main = async () => {    
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue.default({
        el: "#site",
        delimiters: ['${', '}'],
        components: {
            'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
            'modal': () => import(/* webpackChunkName: "modal" */ '../vue/Modal.vue'),
            'loader': () => import(/* webpackChunkName: "loader" */ '../vue/Loader.vue'),
            'algolia-filter': () => import(/* webpackChunkName: "algolia-filter" */ '../vue/AlgoliaFilter.vue'),
            'search-bar-algolia': () => import(/* webpackChunkName: "search-bar-algolia" */ '../vue/SearchBarAlgolia.vue'),
        },
        data: {
            'mobileClass': false,
            'showModal': false,
            'showModal2': false,
            'showModal10': false,
            'loading': false,
            'checked': false
        },

        methods: {
            openMobileNav: function () {
                this.mobileClass = !this.mobileClass;
            },
            loadingForm: function (formName = 'form', event, price = 0) {
                if(price != 0){
                    this.fireGoogleEvent(price, formName);
                }

                this.formSubmit(formName, event, price);
                this.loading = true;
            },
            fireGoogleEvent: function(price, formName) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'generate_lead',
                    'currency': 'EUR',
                    'form_name': formName,
                    'value': price,
                });
            },
            formSubmit: function(formName, event, price) {
                event.preventDefault();
                const formData = new FormData(event.target);
                
                // Get form values
                const recipientName = formData.get('fromName') || '';
                const phone = formData.get('message[Telefoonnummer]') || formData.get('message[Zakelijknummer]') || '';
                const email = formData.get('fromEmail') || '';
                const value = price || 0;

                // Get UTM parameters and GCLID from localStorage with expiration check
                const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
                const utmData = {};
                utmParams.forEach(param => {
                    const storedData = localStorage.getItem(param);
                    if (storedData) {
                        const { value, timestamp } = JSON.parse(storedData);
                        // Check if data is less than 3 days old
                        if (Date.now() - timestamp < 3 * 24 * 60 * 60 * 1000) {
                            utmData[param] = value;
                        } else {
                            localStorage.removeItem(param);
                        }
                    }
                });
                
                const gclidData = localStorage.getItem('gclid');
                const gclid = gclidData ? JSON.parse(gclidData).value : '';

                // Update dataLayer with form data
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'form_submitted',
                    'form_data': {
                        'recipient_name': recipientName,
                        'form_phone': phone,
                        'form_email': email,
                        'form_name': formName,
                        'form_value': value,
                        'page_title': document.title || '',
                        'page_url': window.location.href || '',
                        'timestamp': new Date().toISOString(),
                        'gclid': gclid || '',
                        'utm_source': utmData.utm_source || '',
                        'utm_medium': utmData.utm_medium || '',
                        'utm_campaign': utmData.utm_campaign || '',
                        'utm_term': utmData.utm_term || '',
                        'utm_content': utmData.utm_content || ''
                    }
                });

                // Log form data contents
                for (let pair of formData.entries()) {
                    console.log(pair[0] + ': ' + pair[1]);
                }

                // Submit the form data
                fetch(event.target.action, {
                    method: event.target.method,
                    body: formData
                })
                .then(response => {
                    if (response.ok) {
                        // Handle success
                        console.log('Form submitted successfully');
                    } else {
                        throw new Error('Form submission failed');
                    }
                })
                .catch(error => {
                    console.error('Form submission error:', error);
                });
            }
        },
        mounted() {
            require('./javascript');

            // Track UTM parameters and GCLID
            const urlParams = new URLSearchParams(window.location.search);
            const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
            const gclid = urlParams.get('gclid');

            // Store UTM parameters in localStorage with timestamp
            utmParams.forEach(param => {
                const value = urlParams.get(param);
                if (value) {
                    localStorage.setItem(param, JSON.stringify({
                        value: value,
                        timestamp: Date.now()
                    }));
                }
            });

            // Store GCLID in localStorage with timestamp if present
            if (gclid) {
                localStorage.setItem('gclid', JSON.stringify({
                    value: gclid,
                    timestamp: Date.now()
                }));
            }
            
            require('./swipers');
            require('./polyfill');

            if(localStorage.last){
                if( (Date.now() - localStorage.last ) / (1000*60*60) >= 1){ //Date.now() is in milliseconds, so convert it all to days, and if it's more than 1 day, show the div
                    this.showModal10 = true;
                    localStorage.last = Date.now(); //Reset your timer
                }
            }
            else {
                localStorage.last = Date.now();
                this.showModal10 = true;
            }
        }
    });
};
// Execute async function
main().then( (value) => {
});
